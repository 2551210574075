/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';
import {Link} from "gatsby";
import PropTypes from "prop-types";
import $ from 'jquery';

//SVG Images
import Logo from '../images/logo.svg';
import EspaImage from '../images/epanadvm_footer_2.jpg';
import DatabaseIcon from '../images/database.svg';
import BridgeIcon from'../images/bridge.svg';
import MachineIcon from'../images/machine-learning.svg';
import ApplicationIcon from '../images/casestudies.svg';
import TeamIcon from '../images/team.svg';
import ContactIcon from '../images/contact.svg';

import "./layout.css";

const Layout = ({ children, bodyclass, title }) => {
    if (typeof window !== 'undefined') {
        $(document).ready(function () {

            function checkabsolute(){
                let viewport = $(window).outerHeight();
                let offset = 100;
                let fugureh = $('header.menu figure').outerHeight();
                let navh = $('header.menu nav').outerHeight();
                if(viewport - fugureh - navh - offset > 0){
                    $('.copy').addClass('bottom_pos');
                }else{
                    $('.copy').removeClass('bottom_pos');
                }
            }
            
            //check on resize
            $(window).resize(function(){
                checkabsolute();
            });

            //init active
            $('.current').each(function(){
                if( $(this).next().hasClass("level-2")){
                    var el = $(this).next();
                    setTimeout(function(){
                        console.log(el)
                        el.slideDown(400, 'linear');
                    }, 500);
                    
                }
                $(this).parent().addClass('active');
            });
            $('.current').not('.toggle-sub').each(function(){
                var oldhtml = $(this).html();
                var newhtml = '<span class="current">'+oldhtml+'</span>';
                $(this).replaceWith(newhtml);
                //console.log(newhtml);
                //console.log(img);
                
            });

            
            $('.toggle-sub').click(function(e){
                e.preventDefault();
                console.log($(this));
                if($(this).parent().hasClass('active')){
                    console.log('in1');
                    $(this).parent().parent().children().removeClass('active');
                    if( $(this).next().hasClass("level-2")){
                        $(this).next().slideUp();
                    }
                    
                }else{
                    console.log('in2');
                    $(this).parent().parent().children().removeClass('active');
                    $(this).parent().addClass('active');
                    if( $(this).next().hasClass("level-2")){
                        $(this).next().slideDown();
                    }
                }
                checkabsolute();
            });
            $('.menu-icon').click(function(e){
                $(this).toggleClass('open');
                $('header.menu').toggleClass('menu-open');
            }); 
            $('main:after').click(function(e){
                $(this).toggleClass('open');
                $('header.menu').toggleClass('menu-open');
            }); 
            //init
            checkabsolute();
        });
    }
  return (
    <>
      <Helmet>
          <body className={bodyclass} />
          <script type="text/javascript" src="https://kit.fontawesome.com/169ec9aedd.js" crossorigin="anonymous"></script>
      </Helmet>
      
      
      <header className="menu">
        <Link activeClassName="current" to="/">
            <figure>
                <img src={Logo} alt="The Bridge Database Logo"/>
            </figure>
        </Link>
        <nav>
            <ul className="main">
                <li>
                    <Link activeClassName="current" to="/existing-fragility-curves/"><img src={DatabaseIcon} alt="Generic Fragility Curves"/><span>Generic Fragility Curves</span></Link>
                </li>
                <li>
                    <Link activeClassName="current" partiallyActive={true} to="/bridge-specific/" className="toggle-sub"><img src={BridgeIcon} alt="Bridge Specific Fragility Curves"/><span>Bridge-Specific Fragility Curves</span></Link>
                    <ul className="sub-menu level-2">
                        <li>
                            <Link activeClassName="current" partiallyActive={true} to="/bridge-specific/capacity/" className="toggle-sub">Calculate Critical Component Capacity</Link>
                            <ul className="sub-menu level-3">
                                <li>
                                    <Link activeClassName="current" partiallyActive={true} to="/bridge-specific/capacity/as-built-piers/" className="toggle-sub">As Built Piers</Link>
                                    <ul className="sub-menu level-4">
                                        <li>
                                            <Link activeClassName="current" to="/bridge-specific/capacity/as-built-piers/ls-closed-form/">LS Thresholds: Closed-form relationships</Link>
                                        </li>
                                        <li>
                                            <Link activeClassName="current" to="/bridge-specific/capacity/as-built-piers/ls-analytical/">LS Thresholds: Analytical estimation</Link>
                                        </li>
                                        
                                    </ul>
                                </li>
                                <li>
                                    <Link activeClassName="current" partiallyActive={true} to="/bridge-specific/capacity/retrofitted/" className="toggle-sub">Retrofitted Piers</Link>
                                    <ul className="sub-menu level-4">
                                        <li>
                                            <Link activeClassName="current" to="/bridge-specific/capacity/retrofitted/ls-closed-form/">LS Thresholds: Closed-form relationships</Link>
                                        </li>
                                        <li>
                                            <Link activeClassName="current" to="/bridge-specific/capacity/retrofitted/ls-analytical/">LS Thresholds: Analytical estimation</Link>
                                        </li>
                                        
                                    </ul>
                                </li>
                                <li>
                                    <Link activeClassName="current" partiallyActive={true} to="/bridge-specific/capacity/limit-state/" className="toggle-sub">Limit state definitions in the literature</Link>
                                    <ul className="sub-menu level-4">
                                        <li>
                                            <Link activeClassName="current" to="/bridge-specific/capacity/limit-state/as-built/">As Built Piers</Link>
                                        </li>
                                        <li>
                                            <Link activeClassName="current" to="/bridge-specific/capacity/limit-state/retrofitted/">Retrofitted Piers</Link>
                                        </li>
                                        <li>
                                            <Link activeClassName="current" to="/bridge-specific/capacity/limit-state/bearings/">Bearings</Link>
                                        </li>
                                        <li>
                                            <Link activeClassName="current" to="/bridge-specific/capacity/limit-state/abutments/">Abutments</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link activeClassName="current" partiallyActive={true} to="/bridge-specific/uncertainties/" className="toggle-sub">Uncertainties in capacity and limit state definition</Link>
                            <ul className="sub-menu level-3">
                                <li>
                                    <Link activeClassName="current" to="/bridge-specific/uncertainties/as-built/">As Built Piers</Link>
                                </li>
                                <li>
                                <Link activeClassName="current" to="/bridge-specific/uncertainties/retrofitted-piers/">Retrofitted Piers</Link>
                                </li>
                                <li>
                                    <Link activeClassName="current" to="/bridge-specific/uncertainties/bearings/">Bearings</Link>
                                </li>
                                <li>
                                    <Link activeClassName="current" to="/bridge-specific/uncertainties/abutments/">Abutments</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link activeClassName="current" to="/bridge-specific/vulnerability-curves/">Calculate bridge-specific fragility curves</Link>                          
                        </li>
                    </ul>
                    
                </li>
                <li>
                    <Link activeClassName="current" to="/ml-techniques/"><img src={MachineIcon} alt="Machine Learning Techniques in Fragility Analysis"/><span>ML-based Fragility Analysis</span></Link>
                </li>
                <li>
                    <Link activeClassName="current" to="/case-studies/"><img src={ApplicationIcon} alt="Case Studies"/><span>Case Studies</span></Link>
                </li>
                <li>
                    <Link activeClassName="current" to="/research-team/"><img src={TeamIcon} alt="Research Team"/><span>Research Team</span></Link>
                </li>
                <li>
                    <Link activeClassName="current" to="/contact/"><img src={ContactIcon} alt="Contact"/><span>Contact Us</span></Link>
                </li>
            </ul>
        </nav>
        
        <div className="copy">
            <p><a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/"><img width=""alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" /></a></p>
            <p>© {new Date().getFullYear()} TheBridgeDatabase.<br/>All Rights Reserved.</p>
        </div>
      </header>
      <main>
        <aside>
            <div className="mobilemenu">
                <Link to="/">
                    <figure>
                        <img src={Logo} alt="The Bridge Database Logo"/>
                    </figure>
                </Link>
                <span className="menu-icon">
                    <span className="bars"></span>
                    <span className="bars"></span>
                    <span className="bars"></span>
                </span>
            </div>
            <div className="espaheader">
                <p><span className="underdev">Under Development</span><span className="cite">Please cite this work as:</span>Stefanidou, S., Paraskevopoulos, E., Papanikolaou, V., Kappos, A.J. "An online platform for bridge-specific fragility analysis of as-built and retrofitted bridges", Bulletin of Earthquake Engineering, 20, 1717-1737 (2022). <a href="https://doi.org/10.1007/s10518-021-01299-3" target="_blank">https://doi.org/10.1007/s10518-021-01299-3</a></p>

                <figure>
                    <img src={EspaImage} alt="The Bridge Database - ESPA"/>
                </figure>
            </div>
        </aside>
        <section>
            <div className="espaheader">
                <p><span className="underdev">Under Development</span><span className="cite">Please cite this work as:</span>Stefanidou, S., Paraskevopoulos, E., Papanikolaou, V., Kappos, A.J. "An online platform for bridge-specific fragility analysis of as-built and retrofitted bridges", Bulletin of Earthquake Engineering, 20, 1717-1737 (2022). <a href="https://doi.org/10.1007/s10518-021-01299-3" target="_blank">https://doi.org/10.1007/s10518-021-01299-3</a></p>

                <figure>
                    <img src={EspaImage} alt="The Bridge Database - ESPA"/>
                </figure>
            </div>
            <header>
                <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
            </header>
            <article>
                {children}
            </article>
        </section>
      </main>

      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
